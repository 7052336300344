import SiteButton_WrappingButtonComponent from '@wix/thunderbolt-elements/src/components/SiteButton/viewer/skinComps/BaseButton/WrappingButton.skin';


const SiteButton_WrappingButton = {
  component: SiteButton_WrappingButtonComponent
};


export const components = {
  ['SiteButton_WrappingButton']: SiteButton_WrappingButton
};

